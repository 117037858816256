'use strict'

import SelectManager from 'assets/core/js/module/selectManager'

export default function (): void {
  SelectManager.getSelect('search_offers_accommodation_types').passedElement.element.addEventListener('choice', () => {
    document.querySelectorAll<HTMLInputElement>('.search__quick-filters input').forEach((input) => (input.checked = false))

    setTimeout(() => {
      const values = SelectManager.getValue('search_offers_accommodation_types') as string[]
      const radioEl = Array.from(document.querySelectorAll<HTMLInputElement>('.search__quick-filters input[data-values]')).find((b) => {
        return b.getAttribute('data-values')?.split(',').sort().join(',') === values.sort().join(',')
      })

      if (radioEl) {
        radioEl.checked = true
      }
    }, 0)
  })

  document.querySelectorAll<HTMLInputElement>('.search__quick-filters input').forEach((input) => {
    input.addEventListener('change', () => {
      if (!input.hasAttribute('data-values')) {
        return
      }

      SelectManager.removeValues('search_offers_accommodation_types')

      if (input.hasAttribute('data-type') && input.getAttribute('data-type') === 'accommodation_types') {
        const values = input.getAttribute('data-values')?.split(',')

        if (input.checked) {
          values?.forEach((value) => {
            SelectManager.setValue('search_offers_accommodation_types', value)
          })
        } else {
          values?.forEach((value) => {
            SelectManager.removeValue('search_offers_accommodation_types', value)
          })
        }
      }
    })
  })
}

'use strict'

import Modal from 'assets/core/js/module/modal'

export default function (): void {
  const wrapper = document.querySelector<HTMLElement>('.newsletter__form-data')

  if (!wrapper) {
    return
  }

  const inputEmail = document.getElementById('newsletterInputEmail') as HTMLInputElement
  const modal = new Modal(document.getElementById('modal-newsletter') as HTMLElement)

  document.querySelector('.newsletter__subscription input[type="submit"]')?.addEventListener('click', (e) => {
    e.preventDefault()
    const iframe = document.getElementById('newsletter-iframe') as HTMLIFrameElement

    if (iframe && !iframe.hasAttribute('src')) {
      const params = inputEmail.value ? `?email=${encodeURI(inputEmail.value)}` : ''
      iframe.src = iframe.getAttribute('data-src') + params
    }

    modal.open()
  })
}

'use strict'

import FavoriteRepository, { Repository, Differential, Favorite } from 'assets/core/js/module/favorite/repository'
import Snackbar from 'assets/core/js/module/snackbar'
import dataLayer from 'assets/core/js/module/dataLayer'

type OnUpdateCallback = (registered: boolean) => void

interface DataLayer {
  pageCategory: string
  data: unknown
}

interface Options {
  addedSnackbar?: HTMLElement | null
  addFailedSnackbar?: HTMLElement | null
  removedSnackbar?: HTMLElement | null
  dataLayer?: DataLayer
}

/**
 * Synchronize a specific favorite with a repository. It allows adding and
 * removing a favorite, automatically displaying notification snackbars and
 * pushing added and removed events to the data layer. It also allows listening
 * to updates on a favorite through a callback.
 */
export default class Button {
  namespace: string
  favorite: Favorite
  onUpdate: OnUpdateCallback
  options: Options

  /**
   * Create a new button to synchronize the given favorite with the repository
   * for the given namespace. The given `onUpdate` callback will be triggered
   * each time the favorite is added or removed, with a `registered` boolean
   * parameter indicating the new favorite status. Optionnally, providing
   * `addedSnackbar` or `removedSnackbar` will automatically trigger
   * notification display for the corresponding event using the `Snackbar`
   * component on the given element.
   *
   * Once a button is instanciated, it should be `initialize`'d.
   */
  constructor(namespace: string, favorite: string, onUpdate: OnUpdateCallback, options: Options) {
    this.namespace = namespace
    this.favorite = new Favorite(favorite)
    this.onUpdate = onUpdate
    this.options = options
  }

  /**
   * Initialize the button, activating the synchronization with the favorite
   * repository, starting to trigger the `onUpdate` callback. It returns the
   * current favorite state, `true` when registered and `false` otherwise.
   */
  initialize(): boolean {
    const repository = this.getRepository()
    repository.listen((differential: Differential) => {
      // Check if it was just added.
      const findIndexCallback = (element: Favorite): boolean => {
        return element.sameAs(this.favorite)
      }
      let index = differential.getAdded().findIndex(findIndexCallback)
      if (index !== -1) {
        this.onUpdate(true)
      }

      // Check if it was just removed.
      index = differential.getRemoved().findIndex(findIndexCallback)
      if (index !== -1) {
        this.onUpdate(false)
      }
    })

    return repository.has(this.favorite)
  }

  /**
   * Order the button to add the favorite to the repository. It should
   * typically be used on click on an HTML element. It automatically displays
   * the configured `addedSnackbar` element using the `Snackbar` component.
   */
  add(): void {
    const success = this.getRepository().add([this.favorite])
    if (!success) {
      if ('addFailedSnackbar' in this.options && this.options.addFailedSnackbar !== null) {
        // @TODO: Redesign the snackbar component to have no side effect in the
        // constructor.
        // eslint-disable-next-line no-new
        new Snackbar(this.options.addFailedSnackbar as HTMLElement, {})
      }

      return
    }
    // @TODO: Handle the failure.

    dataLayer.pushInLayer(window.coreGtmId, {
      event: dataLayer.events.FAVORITE_ADDED,
      page_category: this.options.dataLayer?.pageCategory,
      product_data: this.options.dataLayer?.data,
      accommodation_data: null,
    })
    if ('addedSnackbar' in this.options && this.options.addedSnackbar !== null) {
      // @TODO: Redesign the snackbar component to have no side effect in the
      // constructor.
      // eslint-disable-next-line no-new
      new Snackbar(this.options.addedSnackbar as HTMLElement, {})
    }
  }

  /**
   * Order the button to remove the favorite from the repository. It should
   * typically be used on click on an HTML element. It automatically displays
   * the configured `removedSnackbar` element using the `Snackbar` component.
   */
  remove(): void {
    this.getRepository().remove([this.favorite])

    dataLayer.pushInLayer(window.coreGtmId, {
      event: dataLayer.events.FAVORITE_REMOVED,
      page_category: this.options.dataLayer?.pageCategory,
      product_data: this.options.dataLayer?.data,
      accommodation_data: null,
    })
    if ('removedSnackbar' in this.options && this.options.removedSnackbar !== null) {
      // @TODO: Redesign the snackbar component to have no side effect in the
      // constructor.
      // eslint-disable-next-line no-new
      new Snackbar(this.options.removedSnackbar as HTMLElement, {})
    }
  }

  private getRepository(): Repository {
    return FavoriteRepository.getRepository(this.namespace)
  }
}

'use strict'

import * as coreCommon from 'assets/core/js/common'
import SliderManager from 'assets/core/js/module/sliderManager'

import type { SwiperOptions } from 'swiper/types/swiper-options'
import type Swiper from 'swiper'

export default function (el: HTMLElement, config = {}, lazy = true): string | undefined {
  if (!el || !(el instanceof Element)) {
    return
  }

  const defaultConfig: SwiperOptions = {
    // set loop to false as a temporary fix (setting it to true will increase CLS)
    // https://github.com/nolimits4web/swiper/issues/4076
    loop: false,
    navigation: {
      nextEl: el.querySelector<HTMLElement>('.swiper-button-next'),
      prevEl: el.querySelector<HTMLElement>('.swiper-button-prev'),
    },
    grabCursor: true,
    speed: 300,
    slidesPerView: 'auto',
    spaceBetween: 16,
    on: {
      init: function () {
        coreCommon.initLazyLoading()
      },
    },
  }

  const paginationEl = el.querySelector<HTMLElement>('.swiper-pagination')

  if (paginationEl) {
    defaultConfig.pagination = {
      el: paginationEl,
      type: 'custom',
      renderCustom: function (swiper: Swiper, current: number, total: number) {
        return `${current}/${total}`
      },
    }
  }

  return SliderManager.initSlider(el, Object.assign({}, defaultConfig, config), lazy)
}

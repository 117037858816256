'use strict'

import tooltipV2 from '@ui/Tooltip/component'

export default function (el: HTMLElement): void {
  el.querySelectorAll('.flags-strips .flag-strip').forEach((el) => {
    const flagEl = el.querySelector<HTMLElement>('.strip-text')
    const flagTooltipEl = el.querySelector<HTMLElement>('.strip-tooltip')

    if (flagEl && flagTooltipEl) {
      tooltipV2(flagEl, flagTooltipEl)
    }
  })
}

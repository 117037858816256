'use strict'

import AlertManager from 'assets/core/js/module/alertManager'

export default function (): void {
  const el = document.querySelector('.search__form-section .dca-alert')

  if (el) {
    AlertManager.initAlert('internal-error-alert', el, {
      hideAfter: 10,
      showAfter: 1,
    })
  }
}

'use strict'

import CardsHighlightSlider from 'assets/themes/campings/js/module/cardsHighlightSlider'

export default function (): void {
  document.querySelectorAll('.cms-tabs .tab').forEach(function (el, index) {
    el.addEventListener('click', function (e) {
      e.preventDefault()

      const parent = el.closest('.cms-tabs')

      parent?.querySelectorAll<HTMLElement>('.tab-content').forEach(function (el) {
        el.style.display = 'none'
      })

      const content = (el.parentNode as HTMLElement).nextElementSibling?.querySelectorAll<HTMLElement>('.tab-content')[index]

      if (content) {
        content.style.display = 'block'
      }

      parent?.querySelector('.tab.active')?.classList.remove('active')
      el.classList.add('active')
    })
  })

  document.querySelectorAll('.cms-merchandising .toggle').forEach(function (el) {
    el.addEventListener('click', function (e) {
      e.preventDefault()

      const target = el.previousElementSibling

      el.classList.toggle('active')
      target?.querySelectorAll<HTMLElement>('li:nth-child(n+10)').forEach(function (subEl) {
        subEl.style.display = subEl.style.display === 'block' ? 'none' : 'block'
      })

      el.innerHTML = el.classList.contains('active') ? (el.getAttribute('data-show-less') as string) : (el.getAttribute('data-show-more') as string)
    })
  })

  CardsHighlightSlider('#top-destinations-slider', {
    navigation: {
      nextEl: '#top-destinations-slider-next',
      prevEl: '#top-destinations-slider-prev',
    },
    pagination: {
      clickable: true,
      el: '#top-destinations-slider-pagination',
    },
    loop: document.querySelectorAll('#top-destinations-slider .swiper-slide').length > 1,
  })
}

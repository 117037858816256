'use strict'

import Alert, { AlertConfig } from 'assets/core/js/module/alert'

interface Alerts {
  [key: string]: Alert
}

const alerts: Alerts = {}

export default {
  initAlert(id: string, el: Element, config: AlertConfig = {}): Alert | undefined {
    if (!id) {
      return
    }

    if (alerts[id]) {
      return
    }

    alerts[id] = new Alert(el, config)

    return alerts[id]
  },

  getAlert(id: string): Alert | undefined {
    if (!alerts[id]) {
      return
    }

    return alerts[id]
  },

  removeAlert(id: string): boolean {
    if (!alerts[id]) {
      return false
    }

    // @ts-ignore: always exist after previous if
    alerts[id].destroy()

    delete alerts[id]

    return true
  },
}

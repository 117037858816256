'use strict'

import { arrow, computePosition, Coords, flip, autoPlacement, offset, Placement } from '@floating-ui/dom'
import * as coreCommon from 'assets/core/js/common'

let setTimeoutId: ReturnType<typeof setTimeout> | null = null

const showTooltip = (referenceEl: HTMLElement, tooltipEl: HTMLElement, delay: number): void => {
  delay = coreCommon.isDesktop() ? delay : 0

  if (setTimeoutId) {
    clearTimeout(setTimeoutId)
  }

  setTimeoutId = setTimeout(() => {
    tooltipEl.removeAttribute('hidden')
    updateTooltipPosition(referenceEl, tooltipEl)
      .then(() => true)
      .catch(() => true)
    setTimeoutId = null
  }, delay)
}

const hideTooltip = (e: MouseEvent, referenceEl: HTMLElement, tooltipEl: HTMLElement): void => {
  if (setTimeoutId) {
    clearTimeout(setTimeoutId)
  }

  if (e.relatedTarget !== tooltipEl) {
    tooltipEl.setAttribute('hidden', 'hidden')
  }
}

const updateTooltipPosition = async (referenceEl: HTMLElement, tooltipEl: HTMLElement): Promise<void> => {
  const arrowEl = tooltipEl.querySelector<HTMLElement>(`.dca-tooltip__arrow`)

  if (!arrowEl) {
    return
  }

  const placementMiddleware = tooltipEl.hasAttribute('data-position') ? flip() : autoPlacement({ crossAxis: true })

  const { x, y, placement, middlewareData } = await computePosition(referenceEl, tooltipEl, {
    placement: (tooltipEl.getAttribute('data-position') ?? 'top') as Placement,
    middleware: [placementMiddleware, arrow({ element: arrowEl }), offset(6)],
  })

  tooltipEl.setAttribute('data-placement', tooltipEl.getAttribute('data-position') ?? placement)

  Object.assign(tooltipEl.style, {
    left: `${x}px`,
    top: `${y}px`,
  })

  const { x: arrowX, y: arrowY } = middlewareData.arrow as Coords

  const staticSide = {
    top: 'bottom',
    right: 'left',
    bottom: 'top',
    left: 'right',
  }[placement.split('-')[0] as string] as string

  Object.assign(arrowEl.style, {
    left: arrowX != null ? `${arrowX}px` : '',
    top: arrowY != null ? `${arrowY}px` : '',
    right: '',
    bottom: '',
    [staticSide]: '-4px',
  })
}

export default function (triggerEl: HTMLElement, tooltipEl: HTMLElement, delay: number = 0): void {
  if (!triggerEl || !tooltipEl) {
    return
  }

  if (tooltipEl.hasAttribute('data-init')) {
    return
  }

  tooltipEl.setAttribute('data-init', 'true')

  triggerEl.addEventListener('mouseenter', () => showTooltip(triggerEl, tooltipEl, delay))
  triggerEl.addEventListener('mouseleave', (e) => hideTooltip(e, triggerEl, tooltipEl))
  tooltipEl.addEventListener('mouseleave', (e) => hideTooltip(e, triggerEl, tooltipEl))

  window.addEventListener('resize', () => {
    if (!tooltipEl.hasAttribute('hidden')) {
      updateTooltipPosition(triggerEl, tooltipEl)
        .then(() => true)
        .catch(() => true)
    }
  })
}

import { getMediaQuerySize } from 'assets/core/js/common'
import ProductCard from '@ui/Product/Card/component'
import Slider from '@ui/Slider/component'

export default (rootEl: HTMLElement): void => {
  if (!rootEl) {
    return
  }

  const sliderEl = rootEl.querySelector<HTMLElement>('.slider')
  const paginationEl = rootEl.querySelector<HTMLElement>('.slider-pagination')
  const navigationNextEl = rootEl.querySelector<HTMLElement>('.slider-navigation [data-direction="right"]')
  const navigationPrevEl = rootEl.querySelector<HTMLElement>('.slider-navigation [data-direction="left"]')

  if (!sliderEl) {
    return
  }

  Slider(
    sliderEl,
    paginationEl,
    navigationNextEl,
    navigationPrevEl,
    {
      breakpoints: {
        [getMediaQuerySize('notMobile', false) as string]: {
          slidesPerGroup: 1,
        },
        [getMediaQuerySize('desktop', false) as string]: {
          slidesPerGroup: 2,
        },
      },
    },
    () => {
      rootEl.querySelectorAll<HTMLElement>('.product-card').forEach((el) => {
        ProductCard(el)
      })
    }
  )
}

'use strict'

export interface AlertConfig {
  hideAfter?: number
  showAfter?: number
}

const defaultConfig: AlertConfig = {
  hideAfter: 5,
  showAfter: 0.25,
}

export default class Alert {
  element!: Element
  config!: AlertConfig
  timeout!: null | ReturnType<typeof setTimeout>
  events!: {
    el: Element
    type: keyof HTMLElementEventMap
    fn: EventListenerOrEventListenerObject
  }[]

  constructor(element: Element, config: AlertConfig) {
    if (!element) {
      return
    }

    this.events = []
    this.element = element
    this.timeout = null
    this.config = Object.assign({}, defaultConfig, config)

    this.initEvents()
  }

  initEvents(): void {
    if (this.config.showAfter) {
      setTimeout(() => {
        this.element && this.element.classList.add('dca-alert--visible')
      }, this.config.showAfter * 1000)
    }

    if (this.config.hideAfter) {
      this.timeout = setTimeout(() => {
        this.close()
      }, this.config.hideAfter * 1000)
    }

    const closeButton = this.element.querySelector('.dca-alert__close')

    if (closeButton) {
      closeButton.addEventListener('click', (e) => {
        e.preventDefault()

        this.close()
      })
    }
  }

  getElement(): Element {
    return this.element
  }

  close(): void {
    this.element.classList.remove('dca-alert--visible')

    const contentTransitionendFn = (): void => {
      this.destroy()
    }

    this.events.push({
      el: this.element,
      type: 'transitionend',
      fn: contentTransitionendFn,
    })

    this.element.addEventListener('transitionend', contentTransitionendFn, false)
  }

  destroy(): void {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    this.events.forEach((event) => {
      event.el.removeEventListener(event.type, event.fn)
    })

    this.element.parentNode?.removeChild(this.element)

    this.events = []
  }
}

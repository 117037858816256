'use strict'

import tooltipV2 from '@ui/Tooltip/component'

const initTooltips = function (rootElement: HTMLElement): void {
  const offerRewardEl = rootElement.querySelector<HTMLElement>('.label')
  const offerRewardTooltipEl = rootElement.querySelector<HTMLElement>('.tooltip')

  if (offerRewardEl && offerRewardTooltipEl) {
    tooltipV2(offerRewardEl, offerRewardTooltipEl)
  }
}

export default function (rootElement: HTMLElement): void {
  setTimeout(() => {
    initTooltips(rootElement)
  }, 0)
}

'use strict'

import autocomplete from 'assets/themes/campings/js/page/homepage/form/autocomplete'
import overlay from 'assets/themes/campings/js/page/homepage/form/overlay'
import filters from 'assets/themes/campings/js/page/homepage/form/filters'

export default function (): void {
  autocomplete()
  overlay()
  filters()
}

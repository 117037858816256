'use strict'

import SliderManager from 'assets/core/js/module/sliderManager'
import * as common from 'assets/core/js/common'

export default function (element: HTMLElement | string, config = {}): void {
  let el

  if (typeof element === 'string') {
    el = document.querySelector<HTMLElement>(element)
  } else {
    el = element
  }

  if (!el) {
    return
  }

  const defaultConfig: { slidesPerView: 'auto'; spaceBetween: number; on: { init: () => void } } = {
    slidesPerView: 'auto',
    spaceBetween: 24,
    on: {
      init: function () {
        common.initLazyLoading()
      },
    },
  }

  SliderManager.initSlider(el, Object.assign({}, defaultConfig, config))
}

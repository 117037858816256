'use strict'

import elementPropertiesManager from 'assets/core/js/module/elementPropertiesManager'
import { Events } from 'assets/core/js/module/searchAutocomplete'
import common from 'assets/themes/campings/js/page/common/index'

import type { Calendar as SearchCalendarType } from '@ui/Search/Calendar/component'

export default function (): void {
  const calendar = elementPropertiesManager.getProperty<SearchCalendarType>(
    document.getElementById('search_offers_checkInDate') as HTMLInputElement,
    'calendar'
  )
  const autocompleteEl = document.getElementById('autocomplete-container')

  if (autocompleteEl) {
    autocompleteEl.addEventListener(Events.AUTOCOMPLETE_ITEM_SELECTED, () => {
      if (!common.isMobile()) {
        calendar?.open()
      }
    })
  }
}

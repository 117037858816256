'use strict'

export interface SnackbarConfig {
  hideAfter?: number
  showAfter?: number
}

const defaultConfig: SnackbarConfig = {
  hideAfter: 5,
  showAfter: 0.25,
}

export default class Snackbar {
  element!: Element
  config!: SnackbarConfig
  timeout!: null | ReturnType<typeof setTimeout>
  autoClose!: boolean
  isReusable!: boolean
  containerEl!: Element
  events!: {
    el: Element
    type: keyof HTMLElementEventMap
    fn: EventListenerOrEventListenerObject
  }[]

  constructor(element: Element, config: SnackbarConfig) {
    if (!element) {
      return
    }

    this.events = []
    this.timeout = null
    this.config = Object.assign({}, defaultConfig, config)
    this.autoClose = element.hasAttribute('data-snackbar-autoclose')
    this.isReusable = element.hasAttribute('data-snackbar-reusable')
    this.element = element

    if (this.isReusable) {
      this.element = element.cloneNode(true) as Element
      this.element.removeAttribute('id')
    }

    const isStatic = element.hasAttribute('data-snackbar-static')

    let containerEl = document.getElementById('dca-snackbars-container')

    if (!containerEl && !isStatic) {
      const div = document.createElement('div')
      div.id = 'dca-snackbars-container'
      containerEl = document.body.appendChild(div)
    }

    this.containerEl = containerEl as Element

    if (!isStatic) {
      this.containerEl.appendChild(this.element)
    }

    this.element.removeAttribute('hidden')

    this.initEvents()
  }

  initEvents(): void {
    if (this.config.showAfter) {
      setTimeout(() => {
        this.element && this.element.classList.add('dca-snackbar--active')
      }, this.config.showAfter * 1000)
    }

    if (this.autoClose && this.config.hideAfter) {
      this.timeout = setTimeout(() => {
        this.close()
      }, this.config.hideAfter * 1000)
    }

    const closeButton = this.element.querySelector('.dca-snackbar__close')

    if (closeButton) {
      closeButton.addEventListener('click', (e) => {
        e.preventDefault()

        this.close()
      })
    }
  }

  getElement(): Element {
    return this.element
  }

  close(): void {
    this.element.classList.remove('dca-snackbar--active')

    const contentTransitionendFn = (): void => {
      this.destroy()
    }

    this.events.push({
      el: this.element,
      type: 'transitionend',
      fn: contentTransitionendFn,
    })

    this.element.addEventListener('transitionend', contentTransitionendFn, false)
  }

  destroy(): void {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    this.events.forEach((event) => {
      event.el.removeEventListener(event.type, event.fn)
    })

    this.element.remove()

    this.events = []
  }
}

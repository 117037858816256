'use strict'

import FavoriteButton from 'assets/core/js/module/favorite/button'

export interface Options {
  addedSnackbar?: HTMLElement
  addFailedSnackbar?: HTMLElement
  removedSnackbar?: HTMLElement
}

class Button {
  element: HTMLElement
  namespace: string
  favorite: string
  pageCategory: string
  options: Options | null

  constructor(element: HTMLElement, namespace: string, favorite: string, pageCategory: string, options: Options | null) {
    this.element = element
    this.namespace = namespace
    this.favorite = favorite
    this.pageCategory = pageCategory
    this.options = options
  }

  initialize(): void {
    let data = null
    const rawData = this.element.getAttribute('data-favorite-datalayer-data')
    if (rawData !== null) {
      try {
        data = JSON.parse(rawData)
      } catch (error) {}
    }

    const button = new FavoriteButton(
      this.namespace,
      this.favorite,
      (registered: boolean) => {
        this.update(registered)
      },
      {
        addedSnackbar: this.options?.addedSnackbar ?? null,
        addFailedSnackbar: this.options?.addFailedSnackbar ?? null,
        removedSnackbar: this.options?.removedSnackbar ?? null,
        dataLayer: {
          pageCategory: this.pageCategory,
          data,
        },
      }
    )

    this.update(button.initialize())

    this.element.addEventListener('click', () => {
      if (this.element.getAttribute('data-favorite-registered') === null) {
        button.add()
      } else {
        button.remove()
      }
    })
  }

  private update(registered: boolean): void {
    if (registered) {
      this.element.setAttribute('data-favorite-registered', '')
    } else {
      this.element.removeAttribute('data-favorite-registered')
    }
  }
}

/**
 * Setup the add/remove to favorite button in a product card, adding a
 * data-favorite-registered attribute when the product is already in favorites,
 * and handling click events to update favorites.
 */
export default (card: HTMLElement, options: Options | null): void => {
  const element = card.querySelector<HTMLElement>('.product-card__favorite')

  if (element !== null && element.hasAttribute('data-favorite-namespace') && element.hasAttribute('data-favorite-product')) {
    const namespace = element.getAttribute('data-favorite-namespace') as string
    const product = element.getAttribute('data-favorite-product') as string
    const pageCategory = element.getAttribute('data-favorite-datalayer-page-category') as string
    const button = new Button(element, namespace, product, pageCategory, options)

    button.initialize()
  }
}

import Slider from '@ui/Slider/component'

export default (rootEl: HTMLElement): void => {
  if (!rootEl) {
    return
  }

  const sliderEl = rootEl.querySelector<HTMLElement>('.slider')
  const paginationEl = rootEl.querySelector<HTMLElement>('.slider-pagination')
  const navigationNextEl = rootEl.querySelector<HTMLElement>('.slider-navigation [data-direction="right"]')
  const navigationPrevEl = rootEl.querySelector<HTMLElement>('.slider-navigation [data-direction="left"]')

  if (!sliderEl) {
    return
  }

  Slider(sliderEl, paginationEl, navigationNextEl, navigationPrevEl, {
    loop: true,
    spaceBetween: 24,
  })
}

'use strict'

import { Events } from 'assets/core/js/module/autocomplete'
import { Events as MobileBottomPanelEvents } from 'assets/core/js/module/mobileBottomPanel'

export default function (): void {
  let visibleElements: string[] = []
  const contentEl = document.querySelector('.homepage__content')

  if (!contentEl) {
    return
  }

  const showFadeOverlay = (el: HTMLElement): void => {
    visibleElements.push(el.id)

    contentEl.classList.add('dca-fade-overlay')
  }

  const hideFadeOverlay = (el: HTMLElement): void => {
    setTimeout(() => {
      if (!visibleElements.includes(el.id)) {
        return
      }

      visibleElements = visibleElements.filter((id) => id !== el.id)

      if (visibleElements.length > 0) {
        return
      }

      contentEl.classList.remove('dca-fade-overlay')
    }, 100)
  }

  const autocompleteEl = document.getElementById('autocomplete-container')
  const calendarEl = document.getElementById('search_offers_checkInDate')
  const selectEls = [document.getElementById('search_offers_accommodation_types'), document.getElementById('search_offers_capacity')]

  if (autocompleteEl) {
    autocompleteEl.addEventListener(Events.AUTOCOMPLETE_OPENED, () => {
      showFadeOverlay(autocompleteEl)
    })
    autocompleteEl.addEventListener(Events.AUTOCOMPLETE_CLOSED, () => {
      hideFadeOverlay(autocompleteEl)
    })
    autocompleteEl.addEventListener(Events.AUTOCOMPLETE_ITEM_SELECTED, () => {
      hideFadeOverlay(autocompleteEl)
    })
  }

  if (calendarEl) {
    calendarEl.addEventListener('calendar.open', () => {
      showFadeOverlay(calendarEl)
    })
    calendarEl.addEventListener('calendar.close', () => {
      hideFadeOverlay(calendarEl)
    })
  }

  document.body.addEventListener(MobileBottomPanelEvents.MOBILE_BOTTOM_PANEL_HIDDEN, () => {
    contentEl.classList.remove('dca-fade-overlay')
  })

  selectEls
    .filter((el) => el !== null)
    .forEach((el) => {
      el?.addEventListener('showDropdown', () => showFadeOverlay(el))
      el?.addEventListener('hideDropdown', () => hideFadeOverlay(el))
    })
}

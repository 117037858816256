import SliderManager from 'assets/core/js/module/sliderManager'

import type { SwiperOptions } from 'swiper/types/swiper-options'

export default (
  sliderEl: HTMLElement,
  paginationEl?: HTMLElement | null,
  navigationNextEl?: HTMLElement | null,
  navigationPrevEl?: HTMLElement | null,
  config: SwiperOptions = {},
  onInit?: () => void
): void => {
  if (!sliderEl) {
    return
  }

  if (!paginationEl) {
    paginationEl = sliderEl.querySelector<HTMLElement>('.slider-pagination')
  }

  if (!navigationNextEl) {
    navigationNextEl = sliderEl.querySelector<HTMLElement>('.slider-navigation [data-direction="right"]')
  }

  if (!navigationPrevEl) {
    navigationPrevEl = sliderEl.querySelector<HTMLElement>('.slider-navigation [data-direction="left"]')
  }

  SliderManager.initSlider(sliderEl, {
    slidesPerView: 'auto',
    spaceBetween: 16,
    pagination: {
      el: paginationEl ?? null,
    },
    navigation: {
      nextEl: navigationNextEl ?? null,
      prevEl: navigationPrevEl ?? null,
    },
    on: {
      navigationPrev: (swiper) => {
        !swiper.isEnd && sliderEl.removeAttribute('data-ended')
      },
      navigationNext: (swiper) => {
        swiper.isEnd && sliderEl.setAttribute('data-ended', 'true')
      },
      toEdge: (swiper) => {
        !swiper.isEnd && sliderEl.removeAttribute('data-ended')
        swiper.isEnd && sliderEl.setAttribute('data-ended', 'true')
      },
    },
    ...config,
  })

  onInit && onInit()
}

'use strict'

import dataLayer from 'assets/core/js/module/dataLayer'

export default function (): void {
  dataLayer.pushOnElementClick(window.coreGtmId, document.querySelectorAll('[data-click-category][data-click-label]'), (data, el) => {
    const clickCategory = el.getAttribute('data-click-category')
    const clickLabel = el.getAttribute('data-click-label')

    return {
      event: dataLayer.events.OLD_HOMEPAGE_CMS,
      HomeClickCategory: clickCategory,
      HomeClickLabel: clickLabel,
    }
  })
}
